<template>
  <div>
    <div>
      <main-list />
    </div>
    <div class="layout-footer">© Created by Smile and Learn</div>
  </div>
</template>
<script>

import { defineComponent, ref } from 'vue';
import MainList from './MainList.vue';
export default defineComponent({
  components:{
     MainList
  },
  setup() {
    return {
      selectedKeys: ref(['2']),
    };
  },
});
</script>
<style>
</style>