import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'


import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import './assets/styles.css';

const store = createStore({
  state () {
    return {
      smilecontents: [
        {
          id: 1,
          title: "Matematik - Geometri",
          contents: [
            {
              id: "SUMS",
              title: "Toplama işlemi alıştırmaları II",
            },
            {
              id: "PERIMETER",
              title: "Çevre nedir?",
            }
          ]
        },
        {
          id: 2,
          title: "Fen Bilgisi - Hayat Bilgisi - Coğrafya",
          contents: [
            {
              id: "HABITATS",
              title: "Su Yaşam Alanlarını Keşfedin",
            },
            {
              id: "ECOSYSTEMS",
              title: "Ekosistemler nedir?",
            }
          ]
        },
        {
          id: 3,
          title: "Fen Bilgisi - Biyoloji",
          contents: [
            {
              id: "VIRUSBACTERIA",
              title: "Virüsler Vs. Bakteriler",
            },
            {
              id: "BRAIN",
              title: "Beyin - Nedir ve Nasıl Çalışır?",
            }
          ]
        },
        {
          id: 4,
          title: "Tarih",
          contents: [
            {
              id: "PREHISTORY",
              title: "Tarih Öncesi - Bilmeniz Gereken 5 Şey",
            },
            {
              id: "METALAGE",
              title: "Maden Devri - Bilmen Gereken 5 Şey",
            }
          ]
        },
        {
          id: 5,
          title: "Yabancı Dil - English",
          contents: [
            {
              id: "FIGURATIVESPEECH",
              title: "Figurative Speech - Personification, Alliteration and Repetition",
            },
            {
              id: "CITY",
              title: "Parts of the City I",
            }
          ]
        },
        {
          id: 6,
          title: "Yabancı Dil - Español",
          contents: [
            {
              id: "CH",
              title: "Fonética para niños - El sonido /CH/",
            },
            {
              id: "HAPPINESS",
              title: "¿Cómo identificar la alegría? - Reconocer emociones para niños",
            }
          ]
        },
        {
          id: 7,
          title: "Rutinler - Diğer",
          contents: [
            {
              id: "TOYS",
              title: "Oyuncaklar",
            },
            {
              id: "MONTHS",
              title: "Yılın ayları",
            }
          ]
        }

      ]
    }
  },
  getters: {
    smilecontents: state => state.smilecontents,
  }
})
const app = createApp(App);
app.use(store)
app.use(Antd).mount("#app");






