<template>
    <template v-for="section in smilecontents" :key="section.id"  >
      <div>
        <h3 class="headline">{{ section.title }}</h3>
        <div class="grid-app">
          <ul class="hs full no-scrollbar">
              <li class="item" v-for="content in section.contents" :key="content.id" >
                <a :href="`https://resources.smileandlearn.net/Turkish/${content.id}.mp4`" target="_blank">
                  <div class="container " :title="content.title">
                    <img :src="`https://resources.smileandlearn.net/Turkish/${content.id}.png`" :alt="content.title" class="image grow">
                    <div class="content-container-body">
                      <div class="content-container-title">{{ content.title }}</div>
                    </div>
                  </div>                    
                </a>
              </li>
            </ul>
        </div>
      </div>
    </template>
</template>

<script>
export default {
  data () { 
    return {
      smilecontents: this.$store.getters.smilecontents,
    }
  },
}
</script>

<style>
:root {
  --gutter: 50px;
}

.grid-app {
  padding: 10px 0;
  display: grid;
  grid-gap: var(--gutter) 0;
  grid-template-columns: var(--gutter) 1fr var(--gutter);
  align-content: start;
}

.grid-app > * {
  grid-column: 2 / -2;
}

.grid-app > .full {
  grid-column: 1 / -1;
}

.hs {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: 384px;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(.75 * var(--gutter));
  margin-bottom: calc(-.25 * var(--gutter));
}

.hs:before,
.hs:after {
  content: '';
  width: 10px;
}


/* Demo styles */

html,
body {
  height: 100%;
}

/* body {
  display: grid;
  place-items: center;
  background: #fff;
} */

ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

.grid-app {
  /* width: 375px;
  height: 667px; */
  overflow-y: scroll;
}

.hs > li,
.item {
  scroll-snap-align: center;
  /* padding: calc(var(--gutter) / 2 * 1.5); */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  align-items: center; */
  justify-self: stretch;
}

.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}




</style>